import React, { useState } from "react";

const PasswordField = ({ formData, handleChange, languages, language }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  return (
    <div className="mb-3 col-md-6">
      <label htmlFor="password" className="form-label">
        {languages[language]?.password.title}:
      </label>
      <div className="input-group">
        <input
          type={isPasswordVisible ? "text" : "password"} // Toggle input type
          className="form-control"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          placeholder={languages[language].password.description}
          required
        />
        <span
          className="input-group-text"
          onClick={togglePasswordVisibility}
          style={{ cursor: "pointer" }}
        >
          {isPasswordVisible ? (
            <i className="bi bi-eye-slash"></i> // Bootstrap eye-slash icon
          ) : (
            <i className="bi bi-eye"></i> // Bootstrap eye icon
          )}
        </span>
      </div>
    </div>
  );
};

export default PasswordField;
