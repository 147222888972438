import React from "react";
import PartnerRegistrationForm from "./PartnerRegistrationForm";
import "./App.css";

function App() {
  return (
    <div className="App">
      <PartnerRegistrationForm />
    </div>
  );
}

export default App;
